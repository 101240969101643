html, body {
    margin: 0px;
    padding: 0px;
    font-family: 'Open Sans', sans-serif;
}

html, body, #root{
    height: 100%;
}

.login-root{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    background-color: #f2f4f8;
}

.login-box{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    padding: 34px;
    /*background-color: #246eba;*/
    border-radius: 10px;
    color: #000;
}

a {
    text-decoration: none;
}

.main-app-box > main > nav {
    padding: 0px 20px;
    background-color: #fff;
}

.menu-info{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    color: #000;
}

.menu-noti-exit{
    display: flex;
    gap: 10px;
    position: relative;
}

.breadcrumbs {
    padding: 0px 20px;
    background-color: #fff;
}

.breadcrumbs > div {
    display: flex;
    width: 100%;
    align-items: center;
    height: 50px;
    color: #000;
}

footer {
    padding: 0px 20px;
    background-color: #fff;
}

footer > div {
    display: flex;
    width: 100%;
    align-items: center;
    height: 50px;
    color: #000;
}

.content {
    padding: 20px 20px;
    display: flex;
    justify-content: center;
}

.content > div {
    background-color: #fff;
    padding: 0px;
}

.logo{
    width: 90px;
}

.logo-login{
    width: 450px;
}

.logo-menu{
    display: flex;
    align-items: center;
    gap: 50px;
}

.container {
    width: 1150px;
}

.report-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 1150px;
    margin-top: 20px;
}
  
.report-table td, .report-table th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 10px;
    white-space: nowrap;
}

.report-table tr:nth-child(even){background-color: #f2f2f2;}

.report-table tr:hover {background-color: #ddd;}

.report-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #246eba;
    color: white;
    font-size: 10px;
}

.sub-th > th, .sub-th > td{
    background-color: #1a5491;
    color: #fff;
}

.sub-close > td{
    background-color: #246eba;
    color: #fff;
}

.input-form > label {
    display: block;
    margin-bottom: 5px;
}

.input-form > select, .input-form > input, .input-form > textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
    width: 320px;
}

.input-form-n2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.input-form-n2 > div:first-child{
    margin-right: 20px;
}

.input-form-n2 > div > label {
    display: block;
    margin-bottom: 5px;
    margin-top: 10px;
}

.input-form-n2 > div > select, .input-form-n2 > div > input, .input-form-n2 > div > textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
    max-width: 100%;
}

.action-buttons {
    margin-top: 20px;
}

input[type='button'], button{
    padding: 15px 20px;
    border-radius: 5px;
    background-color: forestgreen;
    color: #fff;
    border: none;
    cursor: pointer;
    position: relative;
}

input[type='button']:hover, button:hover{
    background-color: green;
}

.menu-system > ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    gap: 20px;
    font-size: 14px;
}

.menu-system > ul > li > a {
    color: #000;
    background-color: #fff;
    padding: 5px 5px;
    border-radius: 5px;
}

.menu-system > ul > li > a:hover {
    color: #444;
    background-color: #fff;
    padding: 5px 5px;
    border-radius: 5px;
}

.menu-noti-exit {
    font-size: 14px;
    cursor: pointer;
}

.exportar-pdf {
    background-color: red !important;
    margin-left: 10px;
}

.exportar-pdf:hover {
    background-color: rgb(134, 6, 6) !important;
}

.exportar-excel {
    background-color: rgb(22, 187, 57) !important;
    margin-left: 10px;
}

.exportar-excel:hover {
    background-color: rgb(19, 139, 45) !important;
}

.exportar-excel:disabled {
    background-color: #ddd !important;
    cursor: initial;
}

fieldset {
    border: 1px solid #000;
    max-width: 100%;
    width: 600px;
}

input[type='button']:disabled {
    background-color: #ddd !important;
    cursor: initial;
}

.table-overflow{
    overflow-x: auto;
}

textarea{
    width: 320px;
    height: 115px;
}

input[type='file']{
    width: 150px;
}

.menu-system-mobile{
    display: none;
    position: absolute;
    left: -170px;
    background-color: #fff;
    padding: 10px;
    top: 30px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.menu-system-mobile > ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.menu-system-mobile > ul > li:not(:first-child) {
    margin-top: 15px;
}

.menu-mobile{
    display: none;
}

.menu-mobile > svg > path{
    fill: #000;
}

.footer-link{
    color: #000;
}

.mobile-exit {
    color: #000;
}

.generate-report > svg {
    position: absolute;
    left: 15px;
    top: 12px;
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}

.btn-login {
    background-color: #185582 !important;
    border: 1px solid white !important;
    border-radius: 30px !important;
}

.btn-login:hover {
    background-color: #3e8ad6 !important;
}

.login-box > h2{
    padding: 0px;
    margin: 0px;
}

.detail-day{
    background-color: #00b2ff;
    padding: 3px 5px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
}

.modal{
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    justify-content: center;
    align-items: center;
}

.modal > div:first-child{
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.5;
}

.modal > div:last-child{
    position: absolute;
    z-index: 1;
    color: #fff;
    width: 500px;
    height: 500px;
    background-color: #fff;
}

.modal > div:last-child > span{
    background-color: red;
    position: absolute;
    right: 0px;
    z-index: 999;
    padding: 3px 10px;
    cursor: pointer;
}

.modal > div:last-child > div{
    padding: 40px 10px 10px 10px;
    overflow-y: auto;
    height: 450px;
}

.macro-detail {
    color: #000;
    font-size: 14px;
    display: inline-flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 5px;
    justify-content: space-between;
    position: relative;

    &:first-child {
        margin-top: 0;
    }

    #del-event-btn {
        background-color: #c5c5c5;
        right: 10px;
        color: #000;
        cursor: pointer;
        position: absolute;
    }
}

.macro-detail > div:first-child {
    display: flex;
}

.macro-detail #key {
    font-weight: bold;
    margin-right: 10px;
}

.macro-detail > div:last-child > span {
    font-size: 10px;
    background-color: #444;
    padding: 2px 10px;
    border-radius: 5px;
    color: #fff;
}

.macro-detail button, #new-event-cancel {
    padding: 2px 10px;
    height: 24px;
    background-color: #246eba;
}

.new-event {
    border-bottom: 1px solid #aaaaaa;
    border-top: 1px solid #aaaaaa;
    margin-top: 8px;
    padding: 16px 16px;

    select {
        margin-right: 10px;
    }

    select, input {
        padding: 6px 10px;
        border-radius: 4px;
        border: 1px solid #555;
    }

    button {
        height: 30px !important;
        margin-left: 8px !important;
        float: right;
        background-color: forestgreen !important;
        color: white !important;
        padding: 0 20px !important;
    }
}

#new-event-cancel {
    padding: 4px 22px !important;
}

#new-event-btn {
    background-color: #1a5491;
    padding: 4px 20px;
}

input[type='radio'], input[type='number'] {
    width: auto !important;
    margin-bottom: 10px;
}

.rmdp-container {
    width: 98%;
}

.rmdp-input{
    border: 1px solid #000 !important;
    padding: 7px !important;
}

@media (max-width: 575.98px) {
    .container {
        width: 320px;
    }
    fieldset{
        width: 292px;
    }
    textarea{
        width: 250px
    }
    .button-exit{
        display: none;
    }
    .menu-system{
        display: none;
    }
    .menu-mobile{
        display: flex;
    }
}

@media (min-width: 575.98px) and (max-width: 767.98px) {
    .container {
        width: 526px;
    }
    .button-exit{
        display: none;
    }
    .menu-system{
        display: none;
    }
    fieldset{
        width: 450px;
    }
    .menu-mobile{
        display: flex;
    }
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
    .container {
        width: 718px;
    }

    .button-exit{
        display: none;
    }
    .menu-system{
        display: none;
    }
    .menu-mobile{
        display: flex;
    }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .container {
        width: 942px;
    }
}

@media (max-width: 1399.98px) {
    
}